import React from 'react'
import { createTheme, Container, Grid } from '@mui/material';
import home from './../assets/home.png'

const theme = createTheme({
  palette: {
    beige: '#F4F3EE',
    green: '#4B5842',
    orange: 'EE964B',
  },
});

function Home() {
  return (
    <div className='Homepage'>
      <Grid container spacing={0} sx={{
        p: 5, 
        py: 0,
        textAlign: 'left',
        }}>
        <Grid xs={12} md={6} lg={7} sx={{p: 8, background: '#F4F3EE'}}>
          <h3>Hi, I'm Annie 👋</h3>
          <h1>I’m a computer science student</h1>
          <p>This site is under construction ☺</p>
        </Grid>
        <Grid xs={12} md={6} lg={5}>
          <img src={home} style={{width: '100%', height: '100%', objectFit: 'cover'}}></img>
        </Grid>
      </Grid>
      <Grid container sx={{pl: 5}}>
        <Grid item xs={12} md={6}  sx={{px:5}}>
        <Container
            sx={{
              background: '#4B5842'}}>
                <br></br><br></br><br></br>
          </Container>
        </Grid>
      </Grid>
      {/* <Grid container sx={{px: 5}}>
        <Grid item xs={4}
          sx={{
            background: '#F4F3EE'
          }}>
            <p>Experience</p>
        </Grid>
        <Grid item xs={4}
          sx={{
            background: '#F4F3EE'
          }}>
            <p>Experience</p>
        </Grid>
        <Grid item xs={4}
          sx={{
            background: '#F4F3EE'
          }}>
            <p>Experience</p>
        </Grid>
      </Grid> */}
    </div>
  )
}

export default Home