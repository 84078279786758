import React from 'react';
import { Container, Button, Grid } from '@mui/material';
import logo from './../assets/logo.svg'

function Navbar() {
  return (
    <div className='navbar'>
      <Grid container sx={{pl:5}}>
        <Grid item xs={12} md={6} sx={{px:5}}>
        <Container
            sx={{
              background: '#4B5842'}}>
                <br></br><br></br>
          </Container>
        </Grid>
      </Grid>
      <Grid container 
        sx={{
          pl: 5,
        }}>
        <Grid item xs={12} md={6} 
          sx={{
            px:5,
            background: '#050517'}}>
          <Container
            sx={{
              height: '100%',
              background: '#4B5842'}}>
                <a href="/"><img src={logo} alt="Annie Zhang" style={{ height: "100%", width: "100%" }}></img></a>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} spacing={2}
          sx={{
            display: 'flex', 
            alignItems: 'center',
            background: '#050517',
            p: 5,
            pl: 0,
            }}>
              {/* <Button variant="text" color="secondary" href="About">About</Button>
              <Button variant="text" href="Experience">Experience</Button>
              <Button variant="text" href="ECs">ECs</Button>
              <Button variant="text" href="Portfolio">Portfolio</Button>
              <Button variant="text" href="Contact">Contact</Button> */}
            </Grid>
      </Grid>
      <Grid container sx={{pl:5}}>
        <Grid item xs={12} md={6}  sx={{px:5}}>
        <Container
            sx={{
              background: '#4B5842'}}>
                <br></br><br></br><br></br>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default Navbar