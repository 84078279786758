import React from 'react';
import { Grid } from '@mui/material';

function Navbar() {
  return (
    <div className='navbar'>
      <Grid container sx={{
        p:5,
        background: '#050517',
        justifyContent: 'right',
        }}>
        <p style={{textAlign: 'right', color: '#F4F3EE'}}>© Annie Zhang 2023</p>
      </Grid>
    </div>
  )
}

export default Navbar